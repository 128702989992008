<template>
  <div class="text-muted pl-2 pr-2 pl-md-3 pr-md-3 d-flex align-items-center">
    <div v-if="niveauId === 0" class="d-flex flex-nowrap">
      <BaseIcon name="star" width="18" height="18" class="text-muted"></BaseIcon>
      <BaseIcon name="star" width="18" height="18" class="text-muted"></BaseIcon>
      <BaseIcon name="star" width="18" height="18" class="text-muted"></BaseIcon>
      <BaseIcon name="star" width="18" height="18" class="text-muted"></BaseIcon>
    </div>
    <div v-if="niveauId === 1" class="d-flex flex-nowrap">
      <BaseIcon name="star" width="18" height="18" class="text-warning" fill="currentColor"></BaseIcon>
      <BaseIcon name="star" width="18" height="18" class="text-muted" fill="currentColor"></BaseIcon>
      <BaseIcon name="star" width="18" height="18" class="text-muted" fill="currentColor"></BaseIcon>
      <BaseIcon name="star" width="18" height="18" class="text-muted" fill="currentColor"></BaseIcon>
    </div>
    <div v-if="niveauId === 2" class="d-flex flex-nowrap">
      <BaseIcon name="star" width="18" height="18" class="text-warning" fill="currentColor"></BaseIcon>
      <BaseIcon name="star" width="18" height="18" class="text-warning" fill="currentColor"></BaseIcon>
      <BaseIcon name="star" width="18" height="18" class="text-muted" fill="currentColor"></BaseIcon>
      <BaseIcon name="star" width="18" height="18" class="text-muted" fill="currentColor"></BaseIcon>
    </div>
    <div v-if="niveauId === 3" class="d-flex flex-nowrap">
      <BaseIcon name="star" width="18" height="18" class="text-warning" fill="currentColor"></BaseIcon>
      <BaseIcon name="star" width="18" height="18" class="text-warning" fill="currentColor"></BaseIcon>
      <BaseIcon name="star" width="18" height="18" class="text-warning" fill="currentColor"></BaseIcon>
      <BaseIcon name="star" width="18" height="18" class="text-muted" fill="currentColor"></BaseIcon>
    </div>
    <div v-if="niveauId === 4" class="d-flex flex-nowrap">
      <BaseIcon name="star" width="18" height="18" class="text-warning" fill="currentColor"></BaseIcon>
      <BaseIcon name="star" width="18" height="18" class="text-warning" fill="currentColor"></BaseIcon>
      <BaseIcon name="star" width="18" height="18" class="text-warning" fill="currentColor"></BaseIcon>
      <BaseIcon name="star" width="18" height="18" class="text-warning" fill="currentColor"></BaseIcon>
    </div>
  </div>
</template>
<script>
import BaseIcon from "@/components/bases/Icon.vue";

export default {
  components: {
    BaseIcon
  },
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  computed: {
    niveauId: function() {
      return this.value;
    }
  }
};
</script>