<template>
  <ContainerListExtend title="Compétence" class="d-flex flex-column w-100">
    <template v-slot:title>
      <div class="p-1 p-md-2 flex-grow-1">
        <h6 class="m-0" style="line-height: 1rem" v-if="competence.competence_libelle_id && data">
          <description-from-list
            :value="competence.competence_libelle_id"
            :data="data.competenceLibelles"
            text="Nouvelle compétence"
          ></description-from-list>
          <span v-if="competence.libelle_precision"> - {{ competence.libelle_precision }} </span>
        </h6>
        <div class="text-muted" v-if="competence.secteur && data">
          <small>
            <description-from-list
              :value="competence.competence_secteur_id"
              :data="data.competenceSecteurs"
              text
            ></description-from-list>
          </small>
        </div>
      </div>
      <ComptencesStars :value="competence.competence_niveau_id"></ComptencesStars>
    </template>
    <template v-slot:form>
      <base-form
        @click="updateCompetence(competence)"
        deleteBtn
        @delete="deleteCompetence(competence)"
        :loading="loading"
      >
        <base-form-row row>
          <base-select
            inputText="Libellé"
            v-model.number="competence.competence_libelle_id"
            :options="data.competenceLibelles"
          ></base-select>
          <base-select
            inputText="Secteur"
            v-model.number="competence.competence_secteur_id"
            :options="data.competenceSecteurs"
          ></base-select>
        </base-form-row>
        <base-form-row row>
          <base-select
            inputText="Type"
            v-model.number="competence.competence_type_id"
            :options="data.competenceTypes"
          ></base-select>
          <base-select
            inputText="Niveau"
            v-model.number="competence.competence_niveau_id"
            :options="data.competenceNiveaux"
          ></base-select>
        </base-form-row>
        <base-form-row row>
          <base-input
            v-model="competence.libelle_precision"
            inputText="Compétence - précision"
            :errors="feedback.libelle_precision"
          ></base-input>
          <base-inputDatePicker
            v-model="competence.date_identification"
            :errors="feedback.date_identification"
            inputText="Date d'identification"
          ></base-inputDatePicker>
        </base-form-row>
        <base-form-row row>
          <base-input-area
            v-model="competence.competence_commentaire"
            text="Commentaire"
            :errors="feedback.competence_commentaire"
          ></base-input-area>
        </base-form-row>
        <base-form-row row class="mt-3">
          <checkbox
            class="mt-1"
            v-model="competence.competence_critique"
            text="Compétence critique"
            :errors="competence.competence_critique"
          ></checkbox>
        </base-form-row>
      </base-form>
    </template>
  </ContainerListExtend>
</template>
<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";

import DescriptionFromList from "@/components/bases/DescriptionFromList";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseSelect from "@/components/bases/Select.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import Checkbox from "@/components/bases/Checkbox.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import ComptencesStars from "@/components/collaborateurs/profil/forms/competence/CompetencesStars.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";

export default {
  name: "CompetencesItem",
  components: {
    ContainerListExtend,
    BaseSelect,
    BaseForm,
    BaseFormRow,
    BaseInputArea,
    Checkbox,
    DescriptionFromList,
    ComptencesStars,
    BaseInput,
    BaseInputDatePicker,
  },
  data() {
    return {
      feedback: {},
      loading: false,
      tabView: "infos",
      statut: null,
    };
  },
  props: ["competence"],
  computed: {
    ...mapGetters({
      data: "collaborateurs/data",
    }),
  },
  methods: {
    ...mapActions({
      _updateCompetence: "collaborateurs/updateCompetence",
      _deleteCompetence: "collaborateurs/deleteCompetence",
    }),
    updateCompetence: function (competence) {
      this.feedback = {};
      this.loading = true;
      this._updateCompetence(competence)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteCompetence: function (competence) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.feedback = {};
      this.loading = true;
      this._deleteCompetence(competence)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style lang="css"></style>
