<template>
  <div class="d-flex flex-column flex-grow-1 rounded">
    <BaseToolBar title="Accompagnement" subtitle="Accompagnement / tutorat du collaborateur"> </BaseToolBar>
    <container-list-extend title="Accompagnement">
      <template v-slot:title>
        <div class="p-2 flex-grow-1">
          <div v-if="collaborateur_tuteur_bilan_date" class="text-success">
            Réalisé le {{ $moment(collaborateur_tuteur_bilan_date).format("DD/MM/YYYY") }}
          </div>
          <div v-else>Accompagnement à réaliser</div>
        </div>
      </template>
      <template v-slot:form>
        <base-form @click="updateTutorat()" :loading="loading">
          <select-collaborateur
            v-model.number="collaborateur_tuteur_id"
            :text="'Indiquez qui est le tuteur du collaborateur'"
          ></select-collaborateur>
          <base-form-row row>
            <base-input-area
              v-model="collaborateur_tuteur_dispositif"
              text="Dispositif"
              :errors="feedback.collaborateur_tuteur_dispositif"
            ></base-input-area>
          </base-form-row>
          <small>Décrire le dispositif mis en place pour accompagner le collaborateur</small>
          <base-form-row row>
            <base-input-area
              v-model="collaborateur_tuteur_bilan"
              text="Bilan"
              :errors="feedback.collaborateur_tuteur_bilan"
            ></base-input-area>
          </base-form-row>
          <small>Rédiger un bilan de la période d'accomagnement (période minimale de 2 mois)</small>
          <base-form-row row>
            <base-inputDatePicker
              v-model="collaborateur_tuteur_bilan_date"
              inputText="Date de réalisation du bilan"
              :errors="feedback.collaborateur_tuteur_bilan_date"
            ></base-inputDatePicker>
          </base-form-row>
          <div class="mt-3">
            En tant que tuteur et à l'issue de la période d'accompagnement du collaborateur vous devez attester que les éléments ci après sont validé :
          </div>
          <div class="mb-3">
            <Checkbox
              class="w-100 pt-3"
              v-model="collaborateur_tuteur_bilan_1"
              text="Le collaborateur maitrise les fondamentaux indispensables à l’activité"
              :errors="feedback.collaborateur_tuteur_bilan_1"
            ></Checkbox>
            <Checkbox
              class="w-100 pt-3"
              v-model="collaborateur_tuteur_bilan_2"
              text="Le collaborateur maitrise les compétences identifiées"
              :errors="feedback.collaborateur_tuteur_bilan_2"
            ></Checkbox>
            <Checkbox
              class="w-100 pt-3"
              v-model="collaborateur_tuteur_bilan_3"
              text="Le collaborateur a un comportement exemplaire sur le chantier ou l’installation"
              :errors="feedback.collaborateur_tuteur_bilan_3"
            ></Checkbox>
            <Checkbox
              class="w-100 pt-3"
              v-model="collaborateur_tuteur_bilan_4"
              text="Le collaborateur maîtrise les modalités SSER Arkadia group et locales"
              :errors="feedback.collaborateur_tuteur_bilan_4"
            ></Checkbox>
            <Checkbox
              class="w-100 pt-3"
              v-model="collaborateur_tuteur_bilan_5"
              text="Le collaborateur connait la procédure d’évacuation et sait réagir face à un évènement"
              :errors="feedback.collaborateur_tuteur_bilan_5"
            ></Checkbox>
          </div>
          <uploader
            :id="'document_accompagnement'"
            class=""
            url="/upload/document"
            file_system="SIRH"
            file_field="accompagnement"
            :file_key="$route.params.id"
            title="Pièces jointes"
          ></uploader>
        </base-form>
      </template>
    </container-list-extend>
    <BaseToolBar title="Compétences" subtitle="Liste des compétences disponibles">
      <BaseButton
        class="btn btn-primary ml-3"
        v-tooltip="'Ajouter une compétence'"
        @click="storeCompetence"
        :loading="loading"
        icon="plus"
        text="Ajouter"
      ></BaseButton>
    </BaseToolBar>
    <div class="border rounded p-3 mb-3 d-flex align-items-center">
      <div class="mr-3"><BaseIcon name="award" color="#dc3545" class="ml-1" width="40px" height="40px" /></div>
      <div class="w-100">
        <div>{{ competences.length }} compétences identifiées</div>
        <div>Note moyenne {{ moyenne }}/4</div>
      </div>
    </div>
    <div class="d-flex flex-column flex-md-row w-100">
      <div class="flex-grow-1 d-flex flex-column mb-3">
        <div v-for="competence in competences" :key="competence.id">
          <competences-item :competence="competence"></competences-item>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapMultiRowFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

import CompetencesItem from "@/components/collaborateurs/profil/forms/competence/CompetencesItem.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import BaseButton from "@/components/bases/Button.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import Checkbox from "@/components/bases/Checkbox.vue";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseIcon from "@/components/bases/Icon.vue";
import SelectCollaborateur from "@/components/bases/SelectCollaborateur.vue";
import Uploader from "@/components/bases/Uploader.vue";

export default {
  components: {
    CompetencesItem,
    BaseToolBar,
    BaseButton,
    BaseForm,
    BaseFormRow,
    BaseInputArea,
    BaseInputDatePicker,
    Checkbox,
    ContainerListExtend,
    BaseIcon,
    SelectCollaborateur,
    Uploader,
  },
  data() {
    return {
      newCompetence: { competence_collaborateur_id: 0 },
      feedback: {},
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      _storeCompetence: "collaborateurs/createCompetence",
      _updateTutorat: "collaborateurs/updateTutorat",
    }),
    storeCompetence: function () {
      this.feedback = {};
      this.loading = true;
      this._storeCompetence(this.newCompetence)
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },

    updateTutorat: function () {
      this.feedback = {};
      this.loading = true;
      this._updateTutorat(this.collaborateur)
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.newCompetence.competence_collaborateur_id = this.id;
  },
  computed: {
    ...mapFields("collaborateurs", [
      "collaborateur",
      "collaborateur.id",
      "collaborateur.collaborateur_tuteur_id",
      "collaborateur.collaborateur_tuteur_dispositif",
      "collaborateur.collaborateur_tuteur_bilan",
      "collaborateur.collaborateur_tuteur_bilan_date",
      "collaborateur.collaborateur_tuteur_bilan_1",
      "collaborateur.collaborateur_tuteur_bilan_2",
      "collaborateur.collaborateur_tuteur_bilan_3",
      "collaborateur.collaborateur_tuteur_bilan_4",
      "collaborateur.collaborateur_tuteur_bilan_5",
    ]),
    ...mapMultiRowFields("collaborateurs", ["competences"]),
    ...mapGetters({
      data: "collaborateurs/data",
    }),
    moyenne() {
      let note = 0;
      if (this.competences.length == 0) return 0;
      this.competences.forEach((comp) => {
        note = note + parseInt(comp.competence_niveau_id);
      });
      return (note / this.competences.length).toFixed(1);
    },
  },
};
</script>
<style lang="css"></style>
